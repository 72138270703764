<template>
  <div class="capital_account">
    <main>
      <div class="balance_box">
        <div class="balance">
          <div class="text">
            <span>账户余额(元)</span><svg-icon icon-class="" />
          </div>
          <h3>5000</h3>
        </div>
        <div class="balance2">
          <div class="withdrawable">
            <span>可提现金额(元)</span>
            <h3>5000</h3>
          </div>
          <div class="line"></div>
          <div class="nonWithdrawable">
            <span>不可提现金额(元)</span>
            <h3>5000</h3>
          </div>
        </div>
        <div>
          <van-button plain type="default" color="#C9C9C9">充值</van-button>
          <van-button type="primary" color="#009A4D">提现</van-button>
        </div>
      </div>

      <div class="behavior">
        <van-cell title="提现记录" is-link value="查看" />
        <van-cell title="我的银行卡" is-link value="管理" />
        <van-cell title="设置交易密码" is-link />
        <van-cell title="实名认证" is-link value="已实名" />
      </div>
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "capital-account",
  data() {
    return {};
  },
  computed: {
    ...mapState("user", ["electronicAcct"]),
  },
  created() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.capital_account {
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 46px;

  main {
    flex: 1;
    padding: 12px 15px;
    .balance_box {
      background: #fff;
      border-radius: 5px;
      margin-bottom: 15px;
      padding: 15px 12px;
      text-align: center;
      .balance{
        padding: 48px 0;
      }
    }
  }
}
</style>
