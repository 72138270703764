var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wallet"},[_c('div',{staticClass:"account_info"},[_c('div',{staticClass:"residual"},[_c('svg-icon',{attrs:{"icon-class":"zhye_icon"}}),_c('span',[_vm._v("账户余额")]),_c('p',[_vm._v(_vm._s(_vm.balance.toFixed(2)))])],1),_c('div',{staticClass:"bank_card",on:{"click":function($event){return _vm.$router.push('bankCard')}}},[_c('svg-icon',{attrs:{"icon-class":"yhk_icon"}}),_c('span',[_vm._v("银行卡")]),_c('p',[_vm._v(_vm._s(_vm.bankCard.length))])],1)]),_c('div',{staticClass:"tips"}),_c('div',{staticClass:"point_card_box"},[_c('h5',{staticClass:"point_card_num"},[_vm._v("积分卡（"+_vm._s(_vm.bank.length)+"）")]),_vm._l((_vm.bank),function(list){return _c('div',{key:list.bankId,staticClass:"point_card",on:{"click":function($event){return _vm.$router.push({
          path: '/userData/integral',
          query: {
            bankId: list.bankId,
            name:
              list.bankId === '0' ? '正品汇平台积分' : ((list.bankName) + "积分"),
          },
        })}}},[_c('div',[_c('h2',[_vm._v(" "+_vm._s(list.bankId === "0" ? "正品汇平台积分" : ("" + (list.bankName)))+" ")]),_c('span',[_vm._v("积分 "+_vm._s(list.integral)+" ")])]),(list.bankId === '0')?_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
            path: '/userData/signIn',
            query: {
              automaticCheckIn: '1',
            },
          })}}},[_vm._v(" 每日签到 ")]):_c('button',{on:{"click":function($event){$event.stopPropagation();_vm.$store.state.App
            ? _vm.$router.push('/pointsMall')
            : _vm.Toast.fail('暂未开通')}}},[_vm._v(" 前往积分商城 "),_c('svg-icon',{attrs:{"icon-class":"arrow_right2"}})],1)])}),(!_vm.bank.length)?_c('p',{staticClass:"none"},[_vm._v("暂无内容")]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }